import React, { useState } from 'react';

function CopiarCodigo() {
  const [copiado, setCopiado] = useState(false);
  const codigoFuente = `
// 1. Sample Data (simulating the presale participants):
const preSaleData = [
  { UserTwitter: '@juanito', UserDisc: 'Juan#1234', Wallet: '0x123...' },
  { UserTwitter: '@maria25', UserDisc: 'Maria25#5678', Wallet: '0xabc...' },
  // ... more presale participants
];

const allFollowersTwitter = ['@juanito', '@pedro88', '@maria25'];
const allFollowersDiscord = ['Juan#1234', 'Ana#9012', 'Maria25#5678'];

// 2. Filter to get eligible participants:
const eligibleParticipants = preSaleData.filter(participant => {
  const isOnTwitter = allFollowersTwitter.includes(participant.UserTwitter);
  const isOnDiscord = allFollowersDiscord.includes(participant.UserDisc);
  return isOnTwitter && isOnDiscord; 
});

// 3. Winner Selection:
const maxWinners = 5; 
const uniqueWinners = []; // List to store UNIQUE winners
const seenWallets = new Set(); // Set to keep track of ALREADY SEEN wallets

while (uniqueWinners.length < maxWinners && eligibleParticipants.length > 0) {
  const randomIndex = Math.floor(Math.random() * eligibleParticipants.length);
  const potentialWinner = eligibleParticipants.splice(randomIndex, 1)[0];

  if (!seenWallets.has(potentialWinner.Wallet)) { // Check if wallet was already selected
    uniqueWinners.push(potentialWinner); // If not, add the winner
    seenWallets.add(potentialWinner.Wallet); // And record their wallet
  }
}

// 4. Save winners in an immutable box (assuming you're using Firebase)

const winnersRef = db.collection("winners").doc("presale"); // Reference to the "presale" document

winnersRef.set({ // Put the 'uniqueWinners' array inside
  winners: uniqueWinners,
  timestamp: firebase.firestore.FieldValue.serverTimestamp() // Record the date and time
})
.catch(error => {
  // If something goes wrong while locking the safe, make sure we know about it
  console.error("Error saving winners:", error); 
});

  `;



  const copiarAlPortapapeles = () => {
    navigator.clipboard.writeText(codigoFuente)
      .then(() => setCopiado(true))
      .catch(err => console.error('Error al copiar:', err));
  };


  return (
    <p style={{ marginBottom: "20px" }} onClick={copiarAlPortapapeles}>
      {copiado ? "CODE COPIED!" : "CLICK TO COPY THE SOURCE CODE."}
    </p>
  );
}

export default CopiarCodigo;
