import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { ethers } from "ethers"; // Import ethers.js

const getApiKey = () => process.env.REACT_APP_API_KEY;
  const getAuthDomain = () => process.env.REACT_APP_AUTH_DOMAIN;
  const getProjectId = () => process.env.REACT_APP_PROJECT_ID;
  const getStorageBucket = () => process.env.REACT_APP_STORAGE_BUCKET;
  const getMessagingSenderId = () => process.env.REACT_APP_MESSAGING_SENDER_ID;
  const getAppId = () => process.env.REACT_APP_APP_ID;
  const getMeasurementId = () => process.env.REACT_APP_MEASUREMENT_ID;
   
  const firebaseConfig = {
    apiKey: getApiKey(),
    authDomain: getAuthDomain(),
    projectId: getProjectId(),
    storageBucket: getStorageBucket(),
    messagingSenderId: getMessagingSenderId(),
    appId: getAppId(),
    measurementId: getMeasurementId(),
  };
  
  firebase.initializeApp(firebaseConfig);


export const isValidEthAddress = (address) => {
  try {
    ethers.utils.getAddress(address);
    return true;
  } catch (error) {
    return false;
  }
};

export const getBalance = async (address) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    return await provider.getBalance(address);
  } catch (error) {
    return ethers.utils.parseEther("0"); // Return 0 in case of error
  }
};



export default firebase;
