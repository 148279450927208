import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export function createScene3(canvasContainer) {

  // Scene, camera, renderer
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(25, 100 / 135, 0.1, 100);
  scene.add(camera);
  camera.position.set(0, 0, 5);
  camera.lookAt(new THREE.Vector3());

  const renderer = new THREE.WebGLRenderer({ alpha: true, canvas: canvasContainer, antialias: true });
  const containerWidth = canvasContainer.clientWidth;
  const containerHeight = canvasContainer.clientHeight;

  // Set the new dimensions for the renderer
  renderer.setSize(158, 294);

  function resizeRenderer() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
  }

  // Controles de órbita (disable panning and zooming with Shift)
  const orbitControls = new OrbitControls(camera, renderer.domElement);
  orbitControls.enableZoom = false; // Prevent zoom using Shift key
  orbitControls.enableDamping = true;
  orbitControls.panSpeed = 0; // Disable panning when Shift key is pressed
  orbitControls.zoomSpeed = 0; // Disable zooming when Shift key is pressed

  // Anima la escena
  const animate = () => {
    orbitControls.update();
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
  };
  animate();

  // Cargar modelo 3D
  const gltfloader = new GLTFLoader();
  gltfloader.load("./model/nfc.gltf", (gltf) => {
    const mesh = gltf.scene;
    scene.add(mesh);
    mesh.scale.set(0.08, 0.08, 0.08);
    mesh.position.set(0, 0, 0);

    // Evento de click (movimiento sin Shift)
    mesh.addEventListener('click', (event) => {
      if (!event.shiftKey) {
        // Implementar la lógica de movimiento deseada
        // Aquí se pueden aplicar transformaciones o mover el modelo
      }
    });
  });

  // Luz direccional
  const light = new THREE.DirectionalLight(0xffffff, 5);
  light.position.set(0, 1, 2);
  scene.add(light);

  const light2 = new THREE.DirectionalLight(0xffffff, 5);
  light2.position.set(0, -1, 4);
  scene.add(light2);


  const light3 = new THREE.DirectionalLight(0xffffff, 5);
  light3.position.set(0, 1, -2);
  scene.add(light3);

  const light4 = new THREE.DirectionalLight(0xffffff, 5);
  light4.position.set(0, -1, -4);
  scene.add(light4);

  return { scene, renderer };
}
